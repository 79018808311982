<template>
  <div>
    <router-view> </router-view>
    <meta
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      name="viewport"
    />
  </div>
</template>
<script>
export default {
  
  created() {
    this.getweb()
  },
  methods: {
    getweb() {
      var ua = navigator.userAgent.toLowerCase();
      if (/android|adr/gi.test(ua)) {
        window.location.href='https://www.zhonglinn.com/phone'
        // window.location.href='http://192.168.1.118:8117/phone'
      } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(ua)) {
        window.location.href='https://www.zhonglinn.com/phone'
        // window.location.href='http://192.168.1.118:8117/phone'
      } else if (/iPad/gi.test(ua)) {
        window.location.href='https://www.zhonglinn.com/phone'
        // window.location.href='http://192.168.1.118:8117/phone'
      }
    },
  },
}
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>
<style lang="scss">
body{
  font-size: 16px !important;
}
.inner {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
}
table {
  border-collapse: collapse !important;
  border: 1px solid #000 !important;
  tr{
    border: 1px solid #000 !important;
  }
  td{
    border: 1px solid #000 !important;
  }
}
@media screen and (max-width: 600px) {
  .inner {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
  }
}
</style>
