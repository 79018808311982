// 发数据请求: 菜单相关的数据请求
import Vue from 'vue';
import axios from 'axios';

// 配置基础域名
//  axios.defaults.baseURL = 'http://localhost:8192/'
axios.defaults.baseURL = 'https://gwapi.zhonglinn.com/'
// axios.defaults.baseURL = 'http://47.108.92.109:8035'
// axios.defaults.baseURL = 'http://192.168.1.118:7040/'
// axios.defaults.baseURL = 'http://192.168.1.118:7047/'

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 在请求发送之前做一些处理
        // const token = localStorage.getItem('Token')
        // const organizeID = $cookies.get("nowOrgId")
        // const uuid = localStorage.getItem('UserID') 
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        // config.headers['X-Token'] = token;
        // config.headers['organizeID'] = organizeID;
        config.headers['ProjectCode'] = 'PRJ018';
        return config;
    },
    error => {
        // 发送失败
        Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data;
        if (response.config.headers.organizeID == null || response.config.headers.organizeID == undefined || response.config.headers.organizeID == '') {
            // 删除cookie
            // window.localStorage.removeItem('UserID');
            // window.localStorage.removeItem('Token');
            // $cookies.remove("nowOrgId");
            // $cookies.remove("nowOrgName");
            // 清空 vuex 用户信息
            // store.commit("SetUserInfo", {});
            // 跳转路由
            // router.replace("/login");
        }
        if (dataAxios) {
            if (dataAxios.tokenExpired) {
                if (dataAxios.tokenExpired == true) {
                    // 删除cookie
                    // window.localStorage.removeItem('UserID');
                    // window.localStorage.removeItem('Token');
                    // $cookies.remove("nowOrgId");
                    // $cookies.remove("nowOrgName");
                    // 清空 vuex 用户信息
                    // store.commit("SetUserInfo", {});
                    // 跳转路由
                    //  router.replace("/login");
                }
            }
        }
        return dataAxios;
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        // errorLog(error);
        return Promise.reject(error);
    }
);

//post方法
export const post = (url, params) => {
    return axios({
        method: 'post',
        url: url,
        data: params
    });
}
//get 方法
export const get = (url, params) => {
    return axios({
        method: 'get',
        url: url,
        params: params
    });
}

// 将axios挂载到vue的原型对象上, 方便在组件中调用this.$http
Vue.prototype.$http = axios
// 导出
export default axios