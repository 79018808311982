import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  // {
  //   path: '',
  //   component: () => import(
  //     '../components/layout.vue'
  //   ),

  // },
  // 因为跳二级所以加ZLGW
  {
    path: '',
    component: () => import(
      '../components/layout.vue'
    ),
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import(
          '../views/index.vue'
        ),
        
        meta: {
          title: '中霖集团'
        },
      },
      
      {
        path: '/home/GroupIntroduction',
        component: () => import(
          '../views/computent/GroupIntroduction.vue'
        ),
        meta: {
          title: '集团介绍'
        },
        redirect: '/home/GroupIntroduction/index',
        children:[
          {
            path: '/home/GroupIntroduction/index',
            component: () => import(
              '../views/computent/index.vue'
            ),
            meta: {
              title: '公司介绍'
            },
          },
          {
            path: '/home/GroupIntroduction/Qywh',
            component: () => import(
              '../views/computent/Qywh.vue'
            ),
            meta: {
              title: '企业文化'
            },
          },
          {
            path: '/home/GroupIntroduction/Zfgs',
            component: () => import(
              '../views/computent/Zfgs.vue'
            ),
            meta: {
              title: '子分公司'
            },
          },
          {
            path: '/home/GroupIntroduction/Xgzz',
            component: () => import(
              '../views/computent/Xgzz.vue'
            ),
            meta: {
              title: '相关资质'
            },
          },
        ]
      },
      {
        path: '/home/ZfgsDetails',
        component: () => import(
          '../views/computent/ZfgsDetails.vue'
        ),
        meta: {
          title: '子分公司详情'
        },
      },
      {
        path: '/home/ExampleDisplay',
        component: () => import(
          '../views/case/ExampleDisplay.vue'
        ),
        meta: {
          title: '示例展示'
        },
        redirect: '/home/ExampleDisplay/Right',
        children: [
          {
            path: '/home/ExampleDisplay/Right',
            component: () => import(
              '../views/case/Right.vue'
            ),
            meta: {
              title: '系统方案'
            },  
          },
          {
            path: '/home/ExampleDisplay/Details',
            component: () => import(
              '../views/case/Details.vue'
            ),
            meta: {
              title: '方案详情'
            },
          }
        ]
      },
      {
        path: '/home/News',
        component: () => import(
          '../views/News.vue'
        ),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: '/home/NewsDetails',
        component: () => import(
          '../views/NewsDetails.vue'
        ),
        meta: {
          title: '新闻资讯详情'
        },
      },
      {
        path: '/home/Research',
        component: () => import(
          '../views/Research/index.vue'
        ),
        redirect: '/home/Research/System',
        meta: {
          title: '中霖研究'
        },
        children: [
          {
            path: '/home/Research/System',
            component: () => import(
              '../views/Research/System.vue'
            ),
            meta: {
              title: '系统方案'
            },
          },
          {
            path: '/home/Research/Application',
            component: () => import(
              '../views/Research/Application.vue'
            ),
            meta: {
              title: '应用方案'
            },
          },
          {
            path: '/home/Research/Details',
            component: () => import(
              '../views/Research/Details.vue'
            ),
            meta: {
              title: '方案详情'
            },
          }
        ]
      },
      {
        path: '/home/Microwave',
        component: () => import(
          '../views/Microwave.vue'
        ),
        meta: {
          title: '凯棱微波'
        },
      },
      {
        path: '/home/Brochure',
        component: () => import(
          '../views/Brochure/Brochure.vue'
        ),
        meta: {
          title: '宣传册'
        },
      },
      {
        path: '/home/BrochureDetails',
        component: () => import(
          '../views/Brochure/BrochureDetails.vue'
        ),
        meta: {
          title: '宣传册'
        },
      },
      {
        path: '/home/ContactUs',
        component: () => import(
          '../views/ContactUs.vue'
        ),
        meta: {
          title: '联系我们'
        },
      },
      {
        path: '/home/Video',
        component: () => import(
          '../views/Video.vue'
        ),
        meta: {
          title: '视频列表'
        },
      },
      {
        path: '/home/ZlDesign',
        component: () => import(
          '../views/ZlDesign/index.vue'
        ),
        meta: {
          title: '中霖设计'
        },
        children:[
          {
            path: '/home/ZlDesign/Detail/:id',
            component: () => import(
              '../views/ZlDesign/DesignDetails.vue'
            ),
          },
        ],
        redirect: '/home/ZlDesign/Detail/0',
      },
      {
        path: '/home/EquManu',
        component: () => import(
          '../views/EquManu/index.vue'
        ),
        meta: {
          title: '装备制造'
        },
        children:[
          {
            path: '/home/EquManu/Detail/:id',
            component: () => import(
              '../views/EquManu/Details.vue'
            ),
          },
        ],
        redirect: '/home/EquManu/Detail/0',
      },
      {
        path: '/home/AspTec',
        component: () => import(
          '../views/AspTec/index.vue'
        ),
        meta: {
          title: '沥青科技'
        },
        children:[
          {
            path: '/home/AspTec/detail/:id',
            component: () => import(
              '../views/AspTec/details.vue'
            ),
          },
        ],
        redirect: '/home/AspTec/detail/0',
      },
      {
        path: '/home/InfTec',
        component: () => import(
          '../views/InfTec/index.vue'
        ),
        meta: {
          title: '信息科技'
        },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: '/ZLGW/',
  routes,

})
router.beforeEach((to, from, next) => {
  //  从其他地方访问是否有这个地址
  let reg=RegExp('http://www.klwave.com')
  if(reg.test(to.path)){
    next(from.fullPath);
    window.open('http://www.klwave.com', '_blank')
  }
    // if(to.matched.length == 0){
    //   from.path ? next({name: from.name}) : next('*')
    // }
    next();
});

export default router
