import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// SEO优化
import metaInfo from 'vue-meta-info'
// 导入封装的http.js
import './utils/http'
// 导入通用调用函数
import CommonFunction from './common/common.js';
Vue.prototype.CommonFunction = CommonFunction; // 绑定到vue上面
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
// 注册百度地图
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'SzAoZPDXuV6OISASTafre6Y2flZQENCt'
})
Vue.config.productionTip = false
Vue.use(element)
new Vue({
  metaInfo,
  router,
  store,
  ViewUI,
  render: h => h(App)
}).$mount('#app')
