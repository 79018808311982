
import axios from 'axios';
let SerHostUrl = axios.defaults.baseURL
// let fileUrl='http://192.168.1.118:7008'
// let fileUrl = 'http://47.108.92.109:5005'
let fileUrl = 'https://fileapi.dingbang.co'
// let fileUrl = 'http://192.168.1.118:7003'
//订单状态
let OrderState = [
    { Text: "未付款", State: 0, Color: "#ee6666" },
    { Text: "未发货", State: 10, Color: "gold" },
    { Text: "已发货", State: 20, Color: "green" },
    { Text: "已完成", State: 30, Color: "#a9cc10" },
    { Text: "已取消", State: -1, Color: "red" },
];
let GetOrderState = (State) => {
    if (State != undefined) {
        return OrderState.find(P => P["State"] == State);
    }
    else {
        return { Text: "", State: 0, Color: "" }
    }
};
//截取字符
let SliceName = (Name, Length) => {
    if (Length != null && Name != null) {
        const isLongTag = Name.length > Length;
        return isLongTag ? `${Name.slice(0, Length)}` : Name;
    } else {
        return Name;
    }
}
let GetTypeName = (str) => {
    return str.substring(str.lastIndexOf(".") + 1);
}
//时间格式化函数
let DateFormat = (date, fmt) => {
    let nowDate;
    if (fmt == '0') fmt = 'yyyy-MM-dd HH:mm:ss'
    if (fmt == '1') fmt = 'yyyy-MM-dd'
    if (typeof date == "string") {
        date = date.replace(/-/g, "/").replace(/T/g, " ");
    }
    if (!fmt) {
        fmt = 'yyyy-MM-dd HH:mm:ss';
    }
    if (date) {
        nowDate = new Date(date);
    } else {
        //nowDate = new Date();
        return ""
    }

    if (nowDate.getFullYear() == 1) return null;

    var o = {
        "M+": nowDate.getMonth() + 1,                 //月份   
        "d+": nowDate.getDate(),                    //日   
        "H+": nowDate.getHours(),                   //小时   
        "m+": nowDate.getMinutes(),                 //分   
        "s+": nowDate.getSeconds(),                 //秒   
        "q+": Math.floor((nowDate.getMonth() + 3) / 3), //季度   
        "S": nowDate.getMilliseconds()             //毫秒   
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (nowDate.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
// 获取当前时间
let DataNow = () => {
    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let month = nowDate.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = nowDate.getDate();
    day = day < 10 ? "0" + day : day;
    let h = nowDate.getHours();
    h = h < 10 ? "0" + h : h;
    let m = nowDate.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = nowDate.getSeconds();
    s = s < 10 ? "0" + s : s;
    // let time = `${year}-${month}-${day} ${h}:${m}:${s}`;
    return `${year}-${month}-${day} ${h}:${m}:${s}`;
}
//时间控件日期转化
let p = (s) => {
    return s < 10 ? '0' + s : s
}
let GetDateTime = (DateTime) => {
    if (DateTime != null && DateTime != undefined && DateTime.length != 0) {
        const d = new Date(DateTime);
        const resDate = d.getFullYear() + '-' + p((d.getMonth() + 1)) + '-' + p(d.getDate());
        const resTime = p(d.getHours()) + ':' + p(d.getMinutes()) + ':' + p(d.getSeconds());
        return resDate + ' ' + resTime;
    } else {
        return '';
    }
}

//去掉Html内容标签
let StripHTML = (strHtml) => {
    var reg = /(<[^>]+>)|(&nbsp;)(<[^>]+>)|(&nbsp;)|(&emsp;)|(-&gt;)|(\r)|(\n)/g;//去除描述里的HTML标签
    var str = strHtml.replace(reg, "");
    str = str.trim().trim().replace(" ", "");
    return str;
}

//获取文件路径
let GetFileHref = (Href, extension) => {
    if (Href != undefined) {
        if (extension == "xlsx" || extension == "xls"
            || extension == "docx" || extension == "doc"
            || extension == "pptx" || extension == "ppt") {
            let FileHref = Href.replace("~", "");
            //FileHref = FileHref.replace("." + extension, ".pdf");
            return SerHostUrl + FileHref;
        }
        else {
            return SerHostUrl + Href.replace("~", "");
        }
    }
}
//常规-下载文件
let LocalDownloadFile = (Obj) => {
    let url = SerHostUrl + Obj.URL.replace("~", "")
    fetch(url, { responseType: 'blob' }).then(res => res.blob()).then(blob => {
        // 将链接地址字符内容转变成blob地址
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        // link.download = url.split('/').pop()
        link.setAttribute("download", Obj.SourceName);
        link.click()
    })
}
export default {
    SerHostUrl,
    GetOrderState,
    SliceName,
    DateFormat,
    GetFileHref,
    LocalDownloadFile,
    GetDateTime,
    DataNow,
    fileUrl,
    GetTypeName,
    StripHTML
}